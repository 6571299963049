import React from "react";
import "../../../App.css";
import OurTeam from "./ourTeam.js";


function OurTeamMain(){
    return(
        <>
        <OurTeam/>
        
        </>
    );
}

export default OurTeamMain;