import React from "react";
import "../../../App.css";
import Timeline from "./events.js";


function EventsMain() {
   return (
       <Timeline />
   );
}


export default EventsMain;